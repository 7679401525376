<template>
  <div class="dialog-container" v-show="visible">
    <div class="dialog-main">
      <div class="close-icon" v-if="!hideCloseIcon" @click="closeDialog">
        <img src="../assets/image/close.png" alt="" />
      </div>
      <div class="dialog-header">
        {{title}}
      </div>
      <div class="dialog-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "nqDialog",
  props: ["hideHeader", "hideFooter",'visible',"hideCloseIcon","title"],
  methods:{
    closeDialog(){
      this.$emit('close')
    }
  }
};
</script>
<style scoped>
.dialog-container {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog-main {
  background: #fff;
  border-radius: 5px;
  padding: 10px;
  width: 82%;
  max-height: 85%;

  min-height: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.dialog-header,
.dialog-footer {
  height: 30px;
  font-size: 16px;
}
.dialog-header {
  border-bottom: 1px solid #dedede;
}
.dialog-body {
  flex-grow: 1;
   max-height: 85%;
  overflow-y:auto;
  padding:10px 0px;
}
.dialog-footer {
 
  border-top: 1px solid #dedede;
}
.close-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.close-icon img{
  width:100%
}
</style>